/* ==================================================
*	01 - media screen and (max-width: 1440px)
*	02 - media screen and (max-width: 1280px)
*	03 - media screen and (max-width: 1199px)
*	04 - media screen and (max-width: 991px)
*	05 - media screen and (max-width: 767px)
*	06 - media screen and (max-width: 680px)
*	07 - media screen and (max-width: 580px)
*	08 - media screen and (max-width: 480px)
*	09 - media screen and (max-width: 380px)
*	10 - media screen and (max-width: 320px)
================================================== */
@media screen and (min-width: 1441px) {


	.form-content {
		padding-top: 110px;
		padding-left: 120px;
		padding-right: 15%;
	}
}

@media screen and (max-width: 1440px) {
	.budget-area {
		text-align: left !important;
	}

	.steps-area-fixed {
		position: fixed;
	}

	.steps-area-fixed + .multisteps-form__form {
		margin-left: 360px;
	}

	.steps {
		left: 250px;
	}

	.step-box-content .step-box-text h3 {
		font-size: 22px;
	}

	.form-content {
		padding-top: 55px;
		padding-left: 80px;
		padding-right: 4%;
	}

	.start {
		right: 4%;
		bottom: 20%;
	}

	.home-image {
		width: 900px;
	}

	.form-content h2 {
		font-size: 42px;
	}

	.form-content p {
		font-size: 22px;
	}

	.image-holder {
		width: 490px;
		overflow: hidden;

		img {
			object-fit: cover;
			height: 100%;
			object-position: 79%;
		}
	}
}

@media screen and (max-width: 1280px) {

	.form-content h2 {
		font-size: 40px;
	}

	.form-content p {
		font-size: 22px;
	}

	.budget-area .opti-list li {
		margin-right: 15px;

		&:last-child {
			margin-right: 0;
		}
	}
}

@media screen and (max-width: 1199px) {
	.steps-area-fixed {
		position: fixed;
		width: 100% !important;
		z-index: 99;
	}
	.steps-area .steps {
		left: 0;
		width: 100%;
		text-align: center;
	}

	.steps-area-fixed + .multisteps-form__form {
		margin-left: 0;
		margin-top: 70px;
	}
	.steps-area,
	.multisteps-form__form {
		width: 80%;
		max-width: 100%;
	}

	.image-holder {
		width: 100%;
		height: 100px;
		display: block;
		overflow: hidden;

		img {
			height: auto;
			width: 100%;
		}
	}

	.form-content {
		width: 100%;
		padding-right: 80px;
	}

	.inner {
		display: inline-block;
		width: 100%;
	}

	.steps li {
		display: inline-block;
		margin-right: 80px;
	}

	.steps li span:before {
		display: none;
	}

	.steps li.js-active span:after {
		width: 100px;
		height: 2px !important;
		right: -100px;
		top: 20px;
	}

	.start {
		padding: 30px 0px 50px;
		position: static;
		margin-right: 80px;
		float: right;
	}

	.home-image {
		width: 600px;
	}

	.form-content h2 {
		// padding: 50px 0px 20px 0px;
	}

	.actions ul {
		display: block;

		li {
			display: inline-block;
		}
	}

	.start ul {
		display: block;

		li {
			display: inline-block;
		}
	}
}

@media screen and (max-width: 991px) {
	.pb-100, .pb-200 {
		padding-bottom: 30px;
	}

	.wizard {
		max-width: 100%;
	}

	.image-holder {
		img {
			height: 521px;
			object-fit: cover;
		}
	}

	.actions {
		position: static;
	}

	.start {
		position: static;
		padding-right: 10px;
	}

	.home-image {
		width: 400px;
	}

	.steps {
		left: 0;
		right: 0;
		text-align: center;
	}

	.steps-area-fixed+.multisteps-form__form {
		margin-left: 0;
		margin-top: 70px;
	}

	.steps-area,
	.multisteps-form__form {
		width: 100%;
		max-width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.gender-step-box-content {
		cursor: pointer;
		border-radius: 8px;
		border: 2px solid #e1e1e1;
		padding: 15px;
		display: block;
		min-height: 55px;
		width: 100%;

		input {
			opacity: 0;
		}

		span {
			display: block;
		}

		.step-box-icon {
			margin-bottom: 20px;
		}

		.step-box-text {
			font-size: 24px;
			font-weight: 500;
		}
	}
	
	.steps li {
		display: inline-block;
		margin-right: 30px;
		margin-left: 30px;
	}

	.form-content h2 {
		font-size: 26px;
	}

	.form-content p {
		font-size: 18px;
	}

	.step-box-content {
		margin-bottom: 30px;
	}

	.step-progress {
		margin-left: 0;
		margin-bottom: 30px;
	}

	.form-content .step-no {
		font-family: 'Pretendard-Variable-Black';
		display: block;
		// margin-bottom: 30px;
	}

	.upload-documents .upload-option {
		float: none;
	}

	.budget-area .opti-list li {
		margin-bottom: 20px;
	}

	.plan-icon-text {
		margin-bottom: 20px;
	}

	.plan-icon-text {
		width: 100%;
	}

	.step-inner-content {
		padding-bottom: 50px;
	}

	.budget-area .opti-list li {
		font-size: 14px;
		width: 100%;
	}

	.date-picker span {
		min-width: 70px;
		font-size: 12px;
		top: 20px;
	}

	.actions {
		position: static;
		padding-right: 0;
		margin-right: auto;
		margin-left: auto;
		float: none;
		padding: 0;
		ul {
			display: flex;
		}
		li span {
			height: 60px;
			width: 170px;
			line-height: 60px;
		}
	}

	.start {
		display: flex;
		justify-content: center;
	}

	.start-title h1 {
		font-size: 7.8rem;
	}

	.start-title h2 {
		font-size: 2.1rem;
	}

}

@media screen and (max-width: 580px) {

	.steps li {
		margin-right: 15px;
		margin-left: 15px;
	}

	.steps li.js-active span:after {
		width: 70px;
		height: 2px !important;
		right: -70px;
		top: 19px;
	}

	.form-content {
		width: 100%;
		padding-right: 30px;
		padding-left: 30px;
	}

}


@media screen and (max-width: 460px) {
.rank-select-option {
		margin-top: 30px;

		.rc-draggable-list-draggableRow {
			width: 100%;
			height: 75px;
			color: #666666;
			font-size: 16px;
			position: relative;
			line-height: 75px;
			padding-left: 5px;
			border-radius: 4px;
			margin-bottom: 10px;
			cursor: pointer;
			border: 2px solid #ededed;
			content: "";
			display: block;
			clear: both;

			.rc-draggable-list-handles {
				float: left;
			}

			input {
				opacity: 0;
			}

			.option-number {
				width: 23px;
				float: left;
				height: 75px;
				padding-left: 0 !important;
			}

			.option-text {
				height: 75px;
				font-family: 'Pretendard-Black';
				padding-left: 0 !important;
				overflow: hidden;
				word-break: break-all;
				line-height: normal;
				width: 75%;
				float: left;
				display: flex;
				align-items: center;
			}

			.move {
				height: 75px;
				width: 23px;
				opacity: 0;
				font-size: 1.5rem;
				transition: all 0.5s;
				float: left;
			}

			&:hover .move {
				opacity: 1;
				transition: all 0.5s;
			}

			&:not(:hover) .move {
				opacity: 0;
				transition: all 0.5s;
			}

			&.active {
				border: 2px solid $base-color;

				.move {
					opacity: 1;
					transition: all 0.5s;
				}

				&:after {
					border: 2px solid $base-color;
				}

				&:before {
					display: block;
				}
			}
		}
	}

	.steps li {
		margin-right: 10px;
		margin-left: 10px;
	}

	.steps li.js-active span:after {
		width: 60px;
		right: -60px;
	}

	.home-image {
		width: 250px;
	}

	.start {
		display: flex;
		justify-content: center;
	}

	.start-title h1 {
		font-size: 4.5rem;
	}

	.start-title h2 {
		font-size: 1.2rem;
	}
}